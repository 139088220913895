<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo_morado_fondo_negro.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <v-form ref="registerForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Correo Electrónico"
            required
          ></v-text-field>

          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Contraseña"
            type="password"
            required
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="cafeteros-secondary"
            class="mr-4"
            @click="login"
          >
            Ingresar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'pinia'
import { useAppStore } from '@/store'

export default {
  name: 'LoginUser',

  data: () => ({
    valid: true,
    email: '',
    emailRules: [
      v => !!v || 'Correo Electrónico es requerido',
      v => /.+@.+\..+/.test(v) || 'Correo Electrónico debe ser valido',
    ],
    password: '',
    passwordRules: [
      v => !!v || 'Contraseña es requerida',
      v => (v && v.length >= 8) || 'Contraseña debe ser minimo 8 caracteres'
    ],
  }),

  methods: {
    ...mapActions(useAppStore, ['loginUser']),

    login () {
      this.loginUser({ email: this.email, password: this.password, remember: false })
      this.clearLoginUserData()
      this.$router.push('/')
    },

    clearLoginUserData () {
      this.email = ''
      this.password = ''
      this.valid = true
    }
  },
}
</script>
