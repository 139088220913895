<template>
  <v-app id="base">
    <LeftNavigation v-if="isAuthenticated" />
    <TopNavigation />

    <v-main>
      <v-container fluid class="pt-0">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'pinia';
import { useAppStore } from '@/store'
// import RegisterUser from './components/RegisterUser.vue'
import LeftNavigation from '../src/components/LeftNavigation.vue';
import TopNavigation from '../src/components/TopNavigation.vue';

export default {
  name: 'App',

  components: {
    // RegisterUser,
    LeftNavigation,
    TopNavigation
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState(useAppStore, ['isAuthenticated'])
  }
}
</script>

<style scoped>
  #base {
    background: rgb(var(--v-theme-background));
  }
</style>
