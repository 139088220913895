<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo_color.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <v-form ref="registerUserForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Nombre Completo"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Correo Electrónico"
            required
          ></v-text-field>

          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Contraseña"
            type="password"
            required
          ></v-text-field>

          <v-text-field
            v-model="password_confirmation"
            :rules="[v => (!!v && v) === this.password || 'Las contraseñas deben coincidir']"
            label="Confirmar Contraseña"
            type="password"
            required
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="cafeteros-primary"
            class="mr-4"
            @click="submitUserRegistration"
          >
            Registrarme como cafetero
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'pinia'
import { useAppStore } from '@/store'

export default {
  name: 'RegisterUser',

  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'Nombre Completo es requerido',
    ],
    email: '',
    emailRules: [
    v => !!v || 'Correo Electrónico es requerido',
      v => /.+@.+\..+/.test(v) || 'Correo Electrónico debe ser valido',
    ],
    password: '',
    passwordRules: [
    v => !!v || 'Contraseña es requerida',
      v => (v && v.length >= 8) || 'Contraseña debe ser minimo 8 caracteres'
    ],
    password_confirmation: ''
  }),

  methods: {
    ...mapActions(useAppStore, ['registerUser']),

    async submitUserRegistration () {
      const created = await this.registerUser({ name: this.name, email: this.email, password: this.password, password_confirmation: this.password_confirmation })

      if (created) {
        this.$router.push('/login')
        this.$refs.registerUserForm.reset()
      }
    }
  },
}
</script>
