import { createRouter, createWebHistory } from 'vue-router';

import RegisterUser from '@/views/RegisterUser.vue';
import Home from '@/views/HomeDashboard.vue'
import Login from '@/views/LoginView.vue'
import RegisterPlayer from '@/views/RegisterPlayer.vue';

const routes = [
  {
    path: '/',
    name: 'Cafeteros AF Dashboard',
    component: Home,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/register-user',
    name: 'RegisterUser',
    component: RegisterUser
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register-player',
    name: 'RegisterPlayer',
    component: RegisterPlayer
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
