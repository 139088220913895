<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo_rosa_fondo_negro.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <v-form ref="registerPlayerForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Nombre Completo"
            required
          ></v-text-field>

          <v-text-field
            v-model="nickname"
            :rules="nicknameRules"
            label="Alias/Apodo"
            required
          ></v-text-field>

          <!-- TODO: POSICION SELECT -->
          <v-select
            v-model="position"
            :rules="positionRules"
            label="Posición en la que juegas"
            :items="positions"
          ></v-select>

          <!-- TODO: PIERNA HABIL RADIO BUTTON -->
          <v-radio-group v-model="skilledLeg" :rules="skilledLegRules" label="Pierna Hábil" inline>
            <v-radio label="Izquierda" value="Izq"></v-radio>
            <v-radio label="Derecha" value="Der"></v-radio>
            <v-radio label="Ambas" value="Ambas"></v-radio>
          </v-radio-group>

          <!-- TODO: NACIONALIDAD -->
          <v-select
            v-model="nationality"
            :rules="nationalityRules"
            label="País de Nacimiento"
            :items="allCountries"
          ></v-select>

          <v-text-field
            v-model="fanNationalTeam"
            :disabled="nationality === ''"
            :rules="fanNationalTeamRules"
            :label="`Equipo hincha ${nationality}`"
            required
          ></v-text-field>

          <v-text-field
            v-model="fanGlobalTeam"
            :rules="fanGlobalTeamRules"
            label="Equipo/Selección favorito del mundo"
            required
          ></v-text-field>

          <!-- TODO: FECHA DE NACIMIENTO PUT THE RULES BASED ON FORMAT-->
          <v-text-field
            v-model="dateOfBirth"
            :rules="dateOfBirthRules"
            label="Fecha de Nacimiento (MM/DD/YYYY)"
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="cafeteros-thirdly"
            class="mr-4"
            @click="submitPlayerRegistration"
          >
            Crear Jugador Cafetero
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import countries from 'i18n-iso-countries'
  import { mapActions } from 'pinia'
  import { useAppStore } from '@/store'

  export default {
    name: 'RegisterUser',

    data: () => ({
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Nombre Completo es requerido',
      ],
      nickname: '',
      nicknameRules: [
        v => !!v || 'Alis/Apodo es requerido',
      ],
      position: '',
      positionRules: [
        v => !!v || 'Posicion es requerido',
      ],
      skilledLeg: '',
      skilledLegRules: [
        v => !!v || 'Pierna Habil es requerido',
      ],
      nationality: '',
      nationalityRules: [
        v => !!v || 'País de Nacimiento es requerido',
      ],
      fanNationalTeam: '',
      fanNationalTeamRules: [
        v => !!v || `Equipo hincha ${this.nationality} es requerido`,
      ],
      fanGlobalTeam: '',
      fanGlobalTeamRules: [
        v => !!v || 'Equipo favorito del mundo es requerido',
      ],
      dateOfBirth: '',
      dateOfBirthRules: [
        v => !!v || 'Fecha de nacimiento es requerido',
      ],
      positions: [
        'Arquero',
        'Defensa Central',
        'Lateral',
        'Volante Central',
        'Volante Extremo',
        'Volante Creador',
        'Delantero'
      ],
    }),

    computed: {
      allCountries () {
        countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
        const countryList = countries.getNames('en')
        const arrayCountries = []

        for (let key in countryList) {
          arrayCountries.push(countryList[key])
        }

        return arrayCountries
      }
    },

    methods: {
      ...mapActions(useAppStore, ['savePlayer']),
      submitPlayerRegistration () {
        this.savePlayer({ name: this.name, nickname: this.nickname, position: this.position, skilledLeg: this.skilledLeg,
          nationality: this.nationality, fanNationalTeam: this.fanNationalTeam, fanGlobalTeam: this.fanGlobalTeam, dateOfBirth: this.dateOfBirth })
        this.$refs.registerPlayerForm.reset()
      }
    }
  }
</script>
