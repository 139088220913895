<template>
  <v-navigation-drawer
    app
    permanent
    border="none"
    class="nav"
    :rail="isDrawer"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave">
    <v-list height="68px" border="none">
      <v-list-item
        @click="router.push('/')"
        class="nav__img"
        :prepend-avatar="require('../assets/logo_color.png')"
        size="100px"
        title="Player's name"
        subtitle="Email's user"></v-list-item>
    </v-list>
    <v-divider></v-divider>

    <ul class="nav__list">
      <!-- <li class="nav__list-item" @click="router.push('/')">
        <v-btn variant="text" icon="mdi-view-dashboard" color="text"></v-btn>
        <span v-if="!isDrawer">Dashboard</span>
      </li> -->

      <li class="nav__list-item" @click="this.$router.push('/register-player')">
        <v-btn variant="text" icon="mdi-account-plus" color="text"></v-btn>
        <span v-if="!isDrawer">Registrar Jugador</span>
      </li>

      <li class="nav__list-item" @click="this.$router.push('/cafeteros-matches')">
        <v-btn variant="text" icon="mdi-strategy" color="text"></v-btn>
        <span v-if="!isDrawer">Partidos</span>
      </li>

      <li class="nav__list-item" @click="this.$router.push('/cafeteros-matches')">
        <v-btn variant="text" icon="mdi-clipboard-list-outline" color="text"></v-btn>
        <span v-if="!isDrawer">Convocatorias</span>
      </li>

      <!-- <li class="nav__list-item" @click="router.push('/all-customer-list')">
        <v-btn variant="text" icon="mdi-account-multiple" color="text"></v-btn>
        <span v-if="!isDrawer">Ver Convocatoria</span>
      </li> -->

      <!-- <li class="nav__list-item" @click="onToggleNewProductModal">
        <v-btn variant="text" icon="mdi-open-in-app" color="text" title="Add New Product"></v-btn>
        <span v-if="!isDrawer">Crear Convocatoria</span>
      </li> -->
    </ul>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'LeftNavigation',

  data: () => ({
    isDrawer: true,
  }),

  methods: {
    onMouseEnter () {
      this.isDrawer = false
    },

    onMouseLeave () {
      this.isDrawer = true
    }
  }
}
</script>

<style scoped>
  .nav {
    box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 15px !important;
  }
 .nav__img img {
    border-radius: 50%;
    border: 2px solid white;
  }
 .nav__list {
    list-style-type: none;
    margin-left: 12.5px;
    margin-top: 10px;
  }
 .nav__list-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    transition: background 0.25s ease-out;
  }
 .nav__list-item:hover {
    background: rgba(55, 80, 107, 0.04);
    border-radius: 10px;
  }
 .nav__list-item span {
    font-size: 14.5px;
    color: #37506b;
  }
 .v-list-item-icon {
    color: #000;
    margin-left: 6px;
  }
 .v-list-item-title {
    font-weight: 400 !important;
    color: #000 !important;
  }
</style>
