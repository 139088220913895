// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomTheme',
    themes: {
      myCustomTheme: {
        dark: false,
        colors: {
          primary: '#FF2D80',
          text: '#37506b',
          'text-light': '#717d91',
          background: '#f9fbfc',
          'background-secondary': '#eceef2',
          'background-light': '#FFFFFF',
          success: '#15C39A',
          error: '#FF6663',
          warning: '#FEB144',
          'background-dark': '#000000',
          'cafeteros-primary': '#f49a27',
          'cafeteros-secondary': '#705293',
          'cafeteros-thirdly': '#CF568E'

        },
      },
    },
  },
});
