import subAPI from '../../api/init';

export default {
  async registerUser({ name, email, password, password_confirmation }) {
    // this.updateLoading({ boolean: true });
    try {
      const res = await subAPI
      .post(`/register`, { name, email, password, password_confirmation })
    
      if (res.status === 201) {
        return true
      }
      return false
    } catch (error) {
      console.log(error, 'Something went wrong rgistering the user...');
    }
  },
  
  async loginUser({ email, password, remember }) {
    // this.updateLoading({ boolean: true });
    try {
      await subAPI.get(`/sanctum/csrf-cookie`)
      const res = await subAPI
        .post(`/login`, { email, password, remember })
      
      if (res.status === 200) {
        this.isAuthenticated = true
      }
    } catch (error) {
      console.log(error, 'Something went wrong with the login...');
    }
  },

  async logout () {
    try {
      const res = await subAPI
        .post(`/logout`, {})

      if (res.status === 204) {
        this.isAuthenticated = false
      }
    } catch (error) {
      console.log(error, 'Something went wrong with the logout...');
    }
  },

  async savePlayer({ name, nickname, position, skilledLeg, nationality, fanNationalTeam, fanGlobalTeam, dateOfBirth }) {
    await subAPI.get(`/sanctum/csrf-cookie`)
    await subAPI
      .post(`save-player`, { name, nickname, position, skilledLeg, nationality, fanNationalTeam, fanGlobalTeam, dateOfBirth })
      .then((res) => {
        // this.updateLoading({ boolean: false });

        console.log(res)
      })
      .catch((err) => {
        // this.updateLoading({ boolean: false });

        console.log(err, 'Something went wrong saving the player...');
      });
  }
}
