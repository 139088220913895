<template>
  <v-container>
    <!-- <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo_color.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
    </v-row> -->
    <!-- <LeftNavigation />
    <TopNavigation /> -->
  </v-container>
</template>

<script>
// import LeftNavigation from '../components/LeftNavigation.vue';
// import TopNavigation from '../components/TopNavigation.vue';

export default {
  name: 'HomeDashboard',

  data: () => ({

  }),

  components: {
    // LeftNavigation,
    // TopNavigation
  }
}
</script>
