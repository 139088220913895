<template>
  <v-app-bar app prominent class="app-bar">
    <v-btn
      v-if="routeName !== 'Bienvenidos a Cafeteros AF'"
      class="app-bar__icon-center mr-2"
      icon
      @click="$router.back(-1)">
      <v-icon class="app-bar__back-icon">mdi-arrow-top-left</v-icon>
    </v-btn>
    <v-btn v-else @click="$router.push('/')" icon flat clas="mr-2">
      <v-icon class="app-bar__back-icon">mdi-soccer-field</v-icon>
    </v-btn>

    <v-toolbar-title class="app-bar__tool pa-0">
      <span class="app-bar__title pa-0">{{ routeName }} {{ currentProductSelected }}</span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn
      v-if="!isAuthenticated"
      flat
      clas="mr-2"
      @click="$router.push('login')">
      <v-icon class="app-bar__account-icon">mdi-login</v-icon> Ingresar
    </v-btn>

    <v-btn
      v-if="!isAuthenticated"
      flat
      clas="mr-6"
      @click="$router.push('register-user')">
      <v-icon class="app-bar__account-icon">mdi-account-plus</v-icon> Registrarse
    </v-btn>

    <v-btn
      v-if="isAuthenticated"
      flat
      clas="mr-6"
      @click="logout">
      <v-icon class="app-bar__account-icon">mdi-logout</v-icon> Salir
    </v-btn>

    <!-- <v-btn class="disabled" variant="text" icon="mdi-magnify" color="text"></v-btn>

    <v-btn class="disabled" variant="text" icon="mdi-filter" color="text"></v-btn>

    <v-btn class="disabled" variant="text" icon="mdi-dots-vertical" color="text"></v-btn> -->
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useAppStore } from '@/store'
export default {
  name: 'TopNavigation',

  data: () => ({
    routeName: 'Bienvenidos a Cafeteros AF',
    currentProductSelected: ''
  }),

  computed: {
    ...mapState(useAppStore, ['isAuthenticated'])
  },

  methods: {
    ...mapActions(useAppStore, ['logout']),
    onMouseEnter () {
      this.isDrawer = false
    },

    onMouseLeave () {
      this.isDrawer = true
    }
  }
}
</script>

<style scoped>
.app-bar {
  background: transparent;
  box-shadow: none !important;
  color: rgb(var(--v-theme-text));
  background: rgb(var(--v-theme-background));
}

 .app-bar__tool {
  padding-left: 0 !important;
}

 .app-bar__back-icon {
  font-size: 30px;
  padding: 0 !important;
  transform: rotate(-45deg);
}

 .app-bar__title {
  font-weight: 500;
  font-size: 20px;
}

.app-bar__icon-centre {
  display: flex;
  align-items: center;
}

.app-bar__account-icon {
  font-size: 30px;
  padding: 0 !important;
}

.disabled {
  cursor: not-allowed;
}
</style>